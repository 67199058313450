<template>
  <div class="rotor">
    <div v-if="!addressState">
      Please connect your wallet to use this feature
    </div>

    <div v-if="addressState">TODO: Implement Rotor page.</div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  export default {
    name: 'Rotor',

    components: {},

    setup() {
      // Hooks
      const store = useStore()
      const {
        state: { user },
      } = store

      // Read from state
      const addressState = computed(() => user.walletAddress)

      return {
        addressState,
      }
    },
  }
</script>
